import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { BpeVariableData } from "./bpe.vars.component";
import { S25ItemI } from "../../pojo/S25ItemI";
import { BpeVarsUtil } from "./s25.bpe.vars.util";
import { BpeVarsConst } from "./s25.bpe.const";
import { Doc } from "../system-settings/document-management/s25.document.const";
import { VariableWithOptions } from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";

@Component({
    selector: "s25-ng-bpe-variable",
    template: `
        @if (type) {
            <s25-bpe-vars-dropdown
                class="c-margin-top--quarter"
                [type]="type"
                [(chosen)]="state.variable"
                (chosenChange)="setVariable(state)"
                [scope]="scope"
            ></s25-bpe-vars-dropdown>
        }
        @if (state.variable?.options) {
            <div class="options">
                @for (option of options; track option) {
                    @if (!option.hidden) {
                        @switch (option.type) {
                            @case ("checkbox") {
                                <s25-ng-checkbox
                                    [(modelValue)]="state.options[option.id]"
                                    (modelValueChange)="setVariable(state)"
                                    [attr.data-id]="option.id"
                                >
                                    {{ option.label }}
                                </s25-ng-checkbox>
                            }
                            @case ("dropdown") {
                                <s25-generic-dropdown
                                    [items]="option.options"
                                    [(chosen)]="state.options[option.id]"
                                    (chosenChange)="setVariable(state)"
                                />
                            }
                            @case ("roleDropdown") {
                                <s25-ng-role-dropdown
                                    class="c-margin-top--quarter"
                                    [type]="option.data.type"
                                    [(chosen)]="state.options[option.id]"
                                    (chosenChange)="setVariable(state)"
                                ></s25-ng-role-dropdown>
                            }
                            @case ("searchCriteriaItems") {
                                <s25-ng-multiselect-search-criteria
                                    [type]="option.data.criterion"
                                    [modelBean]="{}"
                                    [popoverOnBody]="true"
                                    [showResults]="true"
                                    (changed)="state.options[option.id] = joinItemIds($event); setVariable(state)"
                                ></s25-ng-multiselect-search-criteria>
                            }
                        }
                    }
                }
            </div>
        }
        @if (state.variable?.hasFormat) {
            <label class="c-margin-top--quarter">
                <span class="selectedVarLabel">Format: </span>
                <s25-bpe-vars-dropdown
                    type="dateFormats"
                    [(chosen)]="state.format"
                    (chosenChange)="setVariable(state)"
                    [scope]="scope"
                ></s25-bpe-vars-dropdown>
            </label>
        }
        @if (state.variable?.hasFormat && state.format) {
            <div>Format Example: {{ state.format.ex }}</div>
        }

        <s25-ng-copy-variable [label]="'Variable'" [text]="state.var" />
        <s25-ng-copy-variable [label]="'Pre Variable'" [text]="state.preVar" />
    `,
    styles: `
        .options s25-ng-checkbox {
            margin-right: 0.5em;
        }

        ::ng-deep
            s25-ng-bpe-variable
            .options
            s25-ng-checkbox[data-id="includeAll"]:has(input[type="checkbox"]:checked)
            + s25-ng-multiselect-search-criteria {
            display: none;
        }

        .options s25-ng-multiselect-search-criteria {
            display: block;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BpeVariableComponent implements OnChanges {
    @Input({ required: true }) state: BpeVariableData;
    @Input() type: keyof typeof BpeVarsConst.Items;
    @Input({ required: true }) codeMode: boolean;
    @Input({ required: true }) scope: Doc.ScopeId = "event";

    options: VariableWithOptions["options"] = [];

    constructor(private cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.state || changes.type || changes.scope) {
            this.setVariable(this.state);
        }
    }

    setVariable(state: BpeVariableData) {
        if (!state) return;
        BpeVarsUtil.setVariable(state, this.codeMode);
        this.setOptions();
        this.cd.detectChanges();
    }

    setOptions() {
        this.options = (this.state?.variable?.options || []).filter(
            (option) => !option.scopes || option.scopes.includes(this.scope),
        );
    }

    joinItemIds(items: S25ItemI[]) {
        return items.map((item) => item.itemId).join(",");
    }
}
